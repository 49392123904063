import React from "react";
import {Col, Row} from "antd";
import * as AuthBackend from "./AuthBackend";
import * as ApplicationBackend from "../backend/ApplicationBackend";
import * as Util from "./Util";
import * as Setting from "../Setting";
import * as WechatMediaLogin from "./WechatMediaLogin";

class ShowQRcodePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      applicationName: props.applicationName !== undefined ? props.applicationName : (props.match === undefined ? null : props.match.params.applicationName),
      owner : props.owner !== undefined ? props.owner : (props.match === undefined ? null : props.match.params.owner),
      application: null,
      msg: null,
      sceneStr: "",
      qrcodeUrl: "",
      checkStatusLoopFunc: null,
    };

    if (this.state.type === "cas" && props.match?.params.casApplicationName !== undefined) {
      this.state.owner = props.match?.params.owner;
      this.state.applicationName = props.match?.params.casApplicationName;
    }
  }

  UNSAFE_componentWillMount() {
      this.getApplicationLogin();
      this.getWechatLoginQRcode();
      this.checkScanStatusLoop(this);
  }

  clearCheckScanStatusLoop() {
    clearInterval(this.state.checkStatusLoopFunc);
  }

  checkScanStatusLoop(that) {
    let timer = 0;
    let checkScanStatusInterval = setInterval(function() {
      let sceneStr = that.state.sceneStr;
      timer = timer + 1;
      if (sceneStr != "") {
        that.checkScanStatus(sceneStr);
      }
      console.log(timer);
      if (60 <= timer) {
        that.clearCheckScanStatusLoop();
      }
    }, 2000);
    that.state.checkStatusLoopFunc = checkScanStatusInterval;
  }

  getApplicationLogin() {
    const oAuthParams = Util.getOAuthGetParameters();
    AuthBackend.getApplicationLogin(oAuthParams)
      .then((res) => {
        if (res.status === "ok") {
          this.setState({
            application: res.data,
          });
        } else {
          // Util.showMessage("error", res.msg);
          this.setState({
            application: res.data,
            msg: res.msg,
          });
        }
      });
  }

  getApplication() {
    if (this.state.applicationName === null) {
      return;
    }

    ApplicationBackend.getApplication("admin", this.state.applicationName)
      .then((application) => {
        this.setState({
          application: application,
        });
      });
  }

  getApplicationObj() {
    if (this.props.application !== undefined) {
      return this.props.application;
    } else {
      return this.state.application;
    }
  }

  getWechatLoginQRcode() {
    const getPageQuery = window.location.href.split("?")[1];
    WechatMediaLogin.getWechatLoginQRcode(getPageQuery)
      .then((res) => {
        this.setState({
          sceneStr: res.sceneStr,
          qrcodeUrl: res.qrcodeUrl,
        });
        sessionStorage.setItem("sceneStr", res.sceneStr);
      });
    return this.state.sceneStr;
  }

  renderQRcode() {
    let qrcodeUrl = this.state.qrcodeUrl;
    return (
      <div>
        {/* {*/}
        {/*  JSON.stringify(silentSignin)*/}
        {/* }*/}
        <div style={{fontSize: 16, textAlign: "left"}}>
          请使用微信扫描下方二维码：
        </div>
        <br />
        <img src={qrcodeUrl} style={{width: "300px", height: "300px"}}></img>
      </div>
    );
  }

  checkScanStatus(sceneStr) {
    WechatMediaLogin.checkWechatLoginStatus(sceneStr)
      .then((res) => {
        if (res.status !== undefined) {
          if (res.status == "error") {
            this.clearCheckScanStatusLoop();
            Setting.showMessage("error", `${res.msg}`);
          } else if (res.status == "ok") {
            const code = res.data;
            const oAuthParams = Util.getOAuthGetParameters();
            const concatChar = oAuthParams?.redirectUri?.includes("?") ? "&" : "?";
            Setting.goToLink(`${oAuthParams.redirectUri}${concatChar}code=${code}&state=${oAuthParams.state}`);
          }
        }
        if (res.loginStatus == "scaned") {
          const getPageQuery = window.location.href.split("?")[1];
          if (res.nextAction == "bindPhone") {
            Setting.goToLink(`${window.location.origin}/bind-phone?${getPageQuery}`);
          } else if (res.nextAction == "personalInfo") {
            Setting.goToLink(`${window.location.origin}/personal-info?${getPageQuery}`);
          }
        }
      });
  }

  render() {
    const application = this.getApplicationObj();
    if (application === null) {
      return Util.renderMessageLarge(this, this.state.msg);
    }

    return (
      <Row>
        <Col span={24} style={{display: "flex", justifyContent: "center"}}>
          <div style={{marginTop: "80px", marginBottom: "50px", textAlign: "center"}}>
            {
              Setting.renderHelmet(application)
            }
            {
              Setting.renderLogo(application)
            }
            {
              this.renderQRcode()
            }
          </div>
        </Col>
      </Row>
    );
  }
}

export default ShowQRcodePage;
