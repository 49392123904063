import React from "react";
import {Button, Col, Form, Input, Row} from "antd";
import * as Setting from "../Setting";
import * as AuthBackend from "./AuthBackend";
import i18next from "i18next";
import * as Util from "./Util";
import * as ApplicationBackend from "../backend/ApplicationBackend";
import * as WechatMediaLogin from "./WechatMediaLogin";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 18,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class CompletePersonalInfoPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      applicationName: props.applicationName !== undefined ? props.applicationName : (props.match === undefined ? null : props.match.params.applicationName),
      owner : props.owner !== undefined ? props.owner : (props.match === undefined ? null : props.match.params.owner),
      application: null,
      msg: null,
      sceneStr: "",
      username: null,
      validEmailOrPhone: false,
      validEmail: false,
      validPhone: false,
    };

    if (this.state.type === "cas" && props.match?.params.casApplicationName !== undefined) {
      this.state.owner = props.match?.params.owner;
      this.state.applicationName = props.match?.params.casApplicationName;
    }
  }

  UNSAFE_componentWillMount() {
      this.getApplicationLogin();
  }

  getApplicationLogin() {
    const oAuthParams = Util.getOAuthGetParameters();
    AuthBackend.getApplicationLogin(oAuthParams)
      .then((res) => {
        if (res.status === "ok") {
          this.setState({
            application: res.data,
          });
        } else {
          this.setState({
            application: res.data,
            msg: res.msg,
          });
        }
      });
  }

  getApplicationObj() {
    if (this.props.application !== undefined) {
      return this.props.application;
    } else {
      return this.state.application;
    }
  }

  renderTip() {
    return (
      <div>
        <div style={{fontSize: 16, textAlign: "center"}}>
          <h4>请填写个人信息</h4>
        </div>
        <br />
      </div>
    );
  }

  onFinish(values) {
    values.sceneStr = sessionStorage.getItem("sceneStr");
    WechatMediaLogin.submitPersonalInfo(values)
      .then((res) => {
        if (res.status == "error") {
          Setting.showMessage("error", `${res.msg}`);
        } else if (res.status == "ok") {
          const code = res.data;
          const oAuthParams = Util.getOAuthGetParameters();
          const concatChar = oAuthParams?.redirectUri?.includes("?") ? "&" : "?";
          Setting.goToLink(`${oAuthParams.redirectUri}${concatChar}code=${code}&state=${oAuthParams.state}`);
        }
      });
  }

  onFinishFailed(values, errorFields, outOfDate) {
    this.form.current.scrollToField(errorFields[0].name);
  }

  renderForm(application) {
    return (
      <Form
        {...formItemLayout}
        ref={this.form}
        name="personalInfo"
        onFinish={(values) => this.onFinish(values)}
        onFinishFailed={(errorInfo) => this.onFinishFailed(errorInfo.values, errorInfo.errorFields, errorInfo.outOfDate)}
        initialValues={{
          application: application.name,
          organization: application.organization,
        }}
        style={{width: !Setting.isMobile() ? "400px" : "250px"}}
        size="large"
      >
        <React.Fragment>
          <Form.Item
            name="displayName"
            key="displayName"
            label={i18next.t("general:Display name")}
            rules={[
            {
                required: true,
                message: i18next.t("signup:Please input your display name!"),
                whitespace: true,
            },
            {
                validator: (rule, value) => {
                    // 只支持中文字、英文字母、数字及_ . ·
                    let displayNameReg = new RegExp(/^([\u4E00-\uFA29]|[a-zA-Z0-9_.·])*$/, "g");
                    if (!displayNameReg.test(value)) {
                        return Promise.reject(i18next.t("signup:Display name must be chinese alphanumeric"));
                    }
                    // 判断字符位数
                    // 字符长度必须大于等于4，一个中文字算2个字符
                    // 字符长度必须小于等于18，一个中文字算2个字符
                    let chineseReg = new RegExp(/[\u4E00-\uFA29]+/, "g");
                    let alphanumericReg = new RegExp(/[a-zA-Z0-9_.·]+/, "g");
                    let chineseLen = value.match(chineseReg) ? value.match(chineseReg).join("").length : 0;
                    let alphanumericLen = value.match(alphanumericReg)? value.match(alphanumericReg).join("").length : 0;
                    if (chineseLen * 2 + alphanumericLen < 4) {
                        return Promise.reject(i18next.t("signup:Display name least 4 digits, a chinese as 2 digits"));
                    }
                    if (chineseLen * 2 + alphanumericLen > 18) {
                        return Promise.reject(i18next.t("signup:Display name most 18 digits, a chinese as 2"));
                    }
                    // 不能为1开头的11位数字
                    let invalidNumReg = new RegExp(/^1\d{10}$/, "g");
                    if (invalidNumReg.test(value)) {
                        return Promise.reject(i18next.t("signup:The input is not valid display name!"));
                    }
                    return Promise.resolve();
                }
            }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            key="email"
            label={i18next.t("general:Email")}
            rules={[
              {
                required: true,
                message: i18next.t("signup:Please input your Email!"),
              },
              {
                validator: (_, value) => {
                  if (this.state.email !== "" && !Setting.isValidEmail(this.state.email)) {
                    this.setState({validEmail: false});
                    return Promise.reject(i18next.t("signup:The input is not valid Email!"));
                  }

                  this.setState({validEmail: true});
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input onChange={e => this.setState({email: e.target.value})} />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </React.Fragment>
      </Form>
    );
  }

  render() {
    const application = this.getApplicationObj();
    if (application === null) {
      return Util.renderMessageLarge(this, this.state.msg);
    }

    return (
      <Row>
        <Col span={24} style={{display: "flex", justifyContent: "center"}}>
          <div style={{marginTop: "80px", marginBottom: "50px", textAlign: "center"}}>
            {
              Setting.renderHelmet(application)
            }
            {
              Setting.renderLogo(application)
            }
            {
              this.renderTip()
            }
            {
              this.renderForm(application)
            }
          </div>
        </Col>
      </Row>
    );
  }
}

export default CompletePersonalInfoPage;
