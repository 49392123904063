import React from "react";
import {Button, Col, Form, Input, Row} from "antd";
import * as Setting from "../Setting";
import * as AuthBackend from "./AuthBackend";
import i18next from "i18next";
import * as Util from "./Util";
import * as ApplicationBackend from "../backend/ApplicationBackend";
import {CountDownInput} from "../common/CountDownInput";
import * as WechatMediaLogin from "./WechatMediaLogin";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 18,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

class BindPhonePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      applicationName: props.applicationName !== undefined ? props.applicationName : (props.match === undefined ? null : props.match.params.applicationName),
      owner : props.owner !== undefined ? props.owner : (props.match === undefined ? null : props.match.params.owner),
      application: null,
      msg: null,
      sceneStr: "",
      username: null,
      validEmailOrPhone: false,
      validEmail: false,
      validPhone: false,
    };

    if (this.state.type === "cas" && props.match?.params.casApplicationName !== undefined) {
      this.state.owner = props.match?.params.owner;
      this.state.applicationName = props.match?.params.casApplicationName;
    }
  }

  UNSAFE_componentWillMount() {
      this.getApplicationLogin();
  }

  getApplicationLogin() {
    const oAuthParams = Util.getOAuthGetParameters();
    AuthBackend.getApplicationLogin(oAuthParams)
      .then((res) => {
        if (res.status === "ok") {
          this.setState({
            application: res.data,
          });
        } else {
          // Util.showMessage("error", res.msg);
          this.setState({
            application: res.data,
            msg: res.msg,
          });
        }
      });
  }

  getApplicationObj() {
    if (this.props.application !== undefined) {
      return this.props.application;
    } else {
      return this.state.application;
    }
  }

  renderTip() {
    return (
      <div>
        <div style={{fontSize: 16, textAlign: "center"}}>
          <h4>请绑定手机号</h4>
        </div>
        <br />
      </div>
    );
  }

  onFinish(values) {
    const application = this.getApplicationObj();
    values.phonePrefix = application.organizationObj.phonePrefix;
    values.sceneStr = sessionStorage.getItem("sceneStr");
    WechatMediaLogin.bindPhone(values)
      .then((res) => {
        if (res.status !== undefined) {
          if (res.status == "error") {
            Setting.showMessage("error", `${res.msg}`);
          } else if (res.status == "ok") {
            const code = res.data;
            const oAuthParams = Util.getOAuthGetParameters();
            const concatChar = oAuthParams?.redirectUri?.includes("?") ? "&" : "?";
            Setting.goToLink(`${oAuthParams.redirectUri}${concatChar}code=${code}&state=${oAuthParams.state}`);
          }
        }

        if (res.loginStatus == "scaned") {
          if (res.nextAction == "personalInfo") {
            const getPageQuery = window.location.href.split("?")[1];
            Setting.goToLink(`${window.location.origin}/personal-info?${getPageQuery}`);
          }
        }
      });
  }

  onFinishFailed(values, errorFields, outOfDate) {
    this.form.current.scrollToField(errorFields[0].name);
  }

  renderForm(application) {
    return (
      <Form
        {...formItemLayout}
        ref={this.form}
        name="bindPhone"
        onFinish={(values) => this.onFinish(values)}
        onFinishFailed={(errorInfo) => this.onFinishFailed(errorInfo.values, errorInfo.errorFields, errorInfo.outOfDate)}
        initialValues={{
          application: application.name,
          organization: application.organization,
        }}
        style={{width: !Setting.isMobile() ? "400px" : "250px"}}
        size="large"
      >
        <React.Fragment>
          <Form.Item
            name="phone"
            key="phone"
            label={i18next.t("general:Phone")}
            rules={[
              {
                required: true,
                message: i18next.t("signup:Please input your phone number!"),
              },
              {
                validator: (_, value) => {
                  if (this.state.phone !== "" && !Setting.isValidPhone(this.state.phone)) {
                    this.setState({validPhone: false});
                    return Promise.reject(i18next.t("signup:The input is not valid Phone!"));
                  }

                  this.setState({validPhone: true});
                  return Promise.resolve();
                }
              }
            ]}
          >
            <Input
              style={{
                width: "100%",
              }}
              addonBefore={`+${this.state.application?.organizationObj.phonePrefix}`}
              onChange={e => this.setState({phone: e.target.value})}
            />
          </Form.Item>
          <Form.Item
            name="phoneCode"
            key="phoneCode"
            label={i18next.t("code:Phone code")}
            rules={[
              {
                required: true,
                message: i18next.t("code:Please input your phone verification code!"),
              },
            ]}
          >
            <CountDownInput
              disabled={!this.state.validPhone}
              onButtonClickArgs={[this.state.phone, "phone", Setting.getApplicationName(application)]}
            />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </React.Fragment>
      </Form>
    );
  }

  render() {
    const application = this.getApplicationObj();
    if (application === null) {
      return Util.renderMessageLarge(this, this.state.msg);
    }

    return (
      <Row>
        <Col span={24} style={{display: "flex", justifyContent: "center"}}>
          <div style={{marginTop: "80px", marginBottom: "50px", textAlign: "center"}}>
            {
              Setting.renderHelmet(application)
            }
            {
              Setting.renderLogo(application)
            }
            {
              this.renderTip()
            }
            {
              this.renderForm(application)
            }
          </div>
        </Col>
      </Row>
    );
  }
}

export default BindPhonePage;
