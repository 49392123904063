import {authConfig} from "./Auth";

export function getWechatLoginQRcode(query) {
  return fetch(`${authConfig.serverUrl}/api/get-wechat-login-qrcode?${query}`, {
    method: "GET",
    credentials: "include",
  }).then(res => res.json());
}

export function checkWechatLoginStatus(sceneStr) {
  return fetch(`${authConfig.serverUrl}/api/check-wechat-login-status?sceneStr=${sceneStr}`, {
    method: "GET",
    credentials: "include",
  }).then(res => res.json());
}

export function bindPhone(values) {
  return fetch(`${authConfig.serverUrl}/api/bind-phone`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(values),
  }).then(res => res.json());
}

export function submitPersonalInfo(values) {
  return fetch(`${authConfig.serverUrl}/api/personal-info`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(values),
  }).then(res => res.json());
}
